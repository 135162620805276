<template>
	<div>
		<a :href="dynamicHref" target="_blank" @click="trackNewsArticleClick(title)">
			<div class="news-card">
				<!-- Use 'imageLoaded' to conditionally apply the 'blurry-image' class -->
				<!-- <img
                    ref="cardImage"
                    v-if="image"
                    :src="imageLoaded ? actualImage : placeholder"
                    :alt="title"
                    class="card-image"
                    loading="lazy"
                    @load="imageLoaded = true"
                /> -->
				<!-- <img
					v-if="actualImage"
					:src="actualImage"
					:alt="title"
					class="card-image"
					loading="lazy"
					@load="onImageLoad"
					@error="onImageError"
				/> -->
				<BlurHashImage
					v-if="actualImage"
					:imageUrl="actualImage"
					:blurHashString="blurHashString"
					:altText="title"
					:class="['card-image', 'img-fluid']"
					@load="onImageLoad"
					@error="onImageError"
				/>
				<div v-else class="image-placeholder"></div>
				<!-- :class="{ 'blurry-image': !imageLoaded }" -->
				<div class="news-card-content">
					<div class="news-card-icon" v-if="icon">
						<img
							class="w-10 0"
							:src="icon"
							:alt="`${title} icon`"
							loading="defer"
							style="width: 4.2rem"
						/>
					</div>
				</div>
				<div class="text-layer">
					<a :href="`/article/${link}`" target="_self">
						<p
							class="title"
							:class="{
								'hovered-title': isHovered,
								'clicked-title': isClicked,
							}"
							@mouseover="isHovered = true"
							@mouseout="isHovered = false"
							@click="isClicked = true"
						>
							{{ title }}
						</p>
					</a>
				</div>
			</div>
		</a>
	</div>
</template>
<script>
import { getAWSStorageAsset } from "@/services/storage";
import BlurHashImage from "../BlurHashImage.vue";

export default {
	name: "CardComponent",
	data() {
		return {
			isHovered: false,
			actualImage: "",
			isClicked: false,
			isMobile: window.innerWidth <= 760,
			imageLoaded: false, // Used to track when the actual image is loaded
			placeholder: require("@/assets/images/newsroom/thumbnail/newsThumbnail-min.jpg"), // Path to the placeholder image
		};
	},
	props: {
		image: {
			type: String,
			default: "",
		},
		title: {
			type: String,
			required: true,
		},
		icon: {
			type: String,
			default: "",
		},
		link: {
			type: String,
			required: true,
		},
		blurHashString: {
			type: String,
			required: true,
		},
		recommendedNewsArticle: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		BlurHashImage,
	},
	computed: {
		dynamicHref() {
			return this.isMobile ? undefined : `/article/${this.link}`;
		},
	},
	methods: {
		onResize() {
			// console.log("Resize called at", window.innerWidth);

			// Trigger a re-render to update the computed properties
			this.isMobile = window.innerWidth <= 760;
		},
		onImageLoad() {
			this.imageLoaded = true;
			// Any other logic we want to execute when the image has finished loading, couldnt think of any rn
		},
		onImageError() {
			// console.log("Image error");
			// If an error occurs while loading the image, set the default image
			this.actualImage = this.placeholder;
		},
		trackNewsArticleClick(articleTitle) {
			if (this.recommendedNewsArticle) {
				this.$gtag.event('click', {
					event_category: 'Recommended News Article',
					event_label: `Clicked on recommended news article ${articleTitle}`,
					value: 1,
				});
			}
		},
		async fetchImage(imageId) {
			try {
				this.actualImage = ""; // Clear the actual image while loading the new one
				// console.log("This is the actual image filename: ", imageId);
				const imageUrl = await getAWSStorageAsset(`NewsArticle/${imageId}`);
				// console.log("This is the recieved actual image filename: ", imageUrl);
				this.actualImage = imageUrl;
			} catch (error) {
				// Handle any errors that occur during the fetch process
				console.error("Error fetching image:", error);
				this.actualImage = this.placeholder;
				this.imageLoaded = true;
			}
		},
	},
	mounted() {
		if (this.image != "" || this.image == undefined || this.image == null) {
			this.fetchImage(this.image);
		} else {
			// console.log(this.image);
			this.actualImage = this.placeholder;
			this.imageLoaded = true;
		}
	},
};
</script>

<style scoped>
.image-placeholder {
	width: 100%;
	height: 200px;
	/* background-color: #f0f0f0; */
}
.blurry-image {
	filter: blur(20px);
	transition: all ease-in-out 5s;
}
.news-card {
	max-width: 300px;
	transition: 0.3s;
	/* box-shadow: 0px 3px 6px #00000096; */
	border: 2px solid #bbbbbb;
	border-radius: 10px;
	opacity: 1;
	aspect-ratio: 1/1;
	overflow: hidden;
	position: relative;
}

.news-card > img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	filter: grayscale(30%);
	transition: 0.5s ease;
	cursor: pointer;
	position: absolute;
	left: 0px;
	top: 0px;
	opacity: 0.9;
	z-index: 1;
}

.news-card > img:hover {
	transform: scale(1.3);
	filter: grayscale(0%);
	opacity: 1;
}

.news-card > .gradient {
	width: 100%;
	height: 100%;
	/* background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0.1),
		rgba(0, 0, 0, 0.5)
	); */
	box-shadow: 0px 3px 6px #00000029;
	border: 2px solid #bbbbbb;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0.2;
}

.news-card > .news-card-content {
	/* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1; */
	/* gap: 4rem; */
	position: absolute;
	z-index: 3;
	margin: 0.2rem;
	opacity: 1;
}

.news-card > .news-card-content > .news-card-icon {
	color: white;
	text-shadow: 0px 0px 0.5rem white;
	width: 4vw;
	height: auto;
	margin-left: 0.8rem;
	margin-top: 0.8rem;
}

.text-layer {
	position: absolute;
	pointer-events: none;
	top: auto;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: end;
	/* background-color: rgba(0, 0, 0, 0.5);  */
	background: linear-gradient(
		0deg,
		rgba(0, 0, 0, 1) 0%,
		rgba(253, 187, 45, 0) 100%
	);

	opacity: 1;
	z-index: 4; /* Make sure the z-index is higher than other elements */
}

.title {
	pointer-events: auto;
	font-size: clamp(0.5rem, 2.5vw, 1rem);
	/* padding: 10px 5px; */
	margin: 1vw;
	font-weight: 600;
	text-align: left;
	color: #fff;
	font-family: 'Inter', sans-serif;
	letter-spacing: -1px;
	transition: color 0.5s ease; /* Slow transition */
	display: -webkit-box;
	-webkit-line-clamp: 3; /* limits the text to 2 lines */
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis; /* adds '...' at the end */
}

.hovered-title {
	color: var(--iq-primary);
}

.clicked-title {
	color: var(--iq-primary);
}
/* Media Query for Devices with Max Width 1204px */
@media (max-width: 768px) {
	.news-card > .news-card-content > .news-card-icon {
		width: 7vw !important;
		height: auto;
	}
}
</style>
