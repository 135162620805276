<template>
	<div>
		<div id="banner" v-if="isBannerDataAvailable">
			<div
				v-if="isLoadingBanner && isBannerDataAvailable"
				class="banner-loading"
			>
				<div class="banner-spinner"></div>
			</div>

			<Banner
				:class="{ 'banner-is-hidden': isLoadingBanner }"
				:categoryType="sliderType"
				@loaded="handleBannerLoaded"
				@update:bannerStatus="handleBannerStatus"
			/>
		</div>
		<!-- League List -->
		<div
			v-show="!isLoadingLeague"
			id="
		league"
		>
			<div
				id="featured-content"
				class="main-header d-flex align-items-center justify-content-center"
			>
				<h2 class="iq-title justify-content-center heading title--header">
					Featured Content
				</h2>
			</div>
			<LeagueList :leagueItems="leagueItems" @isLoading="displayLeagueOnLoad" />
		</div>
		<!-- News List -->
		<div id="news" v-show="isLoadingNews">
			<div
				v-show="isLoadingNews"
				class="main-header d-flex align-items-center justify-content-center"
			>
				<h2 class="iq-title justify-content-center heading title--header">
					News
				</h2>
			</div>
			<!-- News -->
			<NewsLists
				:limit="12"
				@isLoading="displayNewsOnLoad"
				@newsDataAvailable="handleNewsDataAvailability"
			/>

			<div
				v-show="isLoadingNews"
				class="d-flex align-items-center justify-content-center"
			>
				<h3 class="iq-title text-capitalize justify-content-center heading">
					<router-link to="newsroom" class="iq-view-all">
						More Stories
					</router-link>
				</h3>
			</div>
		</div>

		<div id="news" v-if="!hasNewsData">
			<div class="main-header d-flex align-items-center justify-content-center">
				<h2 class="iq-title justify-content-center heading title--header">
					News
				</h2>
			</div>
			<!-- News -->
			<div class="justify-content-center d-flex align-items-center">
				<h2>Sorry, no news available.</h2>
			</div>
		</div>
		<!-- Schedule Table -->
		<div
			id="schedule"
			class="main-header d-flex align-items-center justify-content-center"
		>
			<!-- <Schedule @isLoading="displayScheduleOnLoad" /> -->
			<Schedule />
		</div>
		<!-- Channels List -->
		<div class="main-header d-flex align-items-center justify-content-center">
			<ChannelList />
		</div>
	</div>
</template>
<script>
// import { defineAsyncComponent } from "vue"; // Import defineAsyncComponent

import { core } from "../../config/pluginInit";
import Banner from "./Components/NLSE/Slider/BannerRedesigned.vue";
import LeagueList from "./Components/NLSE/LeagueList";
import NewsLists from "./Components/NLSE/News/NewsLists.vue";
import Schedule from "./Components/NLSE/Schedule/ScheduleTable";
import ChannelList from "./Channel/Channels.vue";
import RSSParser from "rss-parser";
import { has } from "@amcharts/amcharts4/.internal/core/utils/Array";

export default {
	name: "MainPage",
	components: {
		Banner,
		LeagueList,
		ChannelList,
		NewsLists,
		Schedule,
	},
	mounted() {
		core.index();
	},
	data() {
		return {
			isBannerDataAvailable: true,
			isLoadingBanner: true,
			isLoadingLeague: true,
			isLoadingNews: false,
			isLoadingSchedule: true,
			hasNewsData: true,
			sliderType: "Home",
			homeSliderData: [],
			featuredItems: [],
			channelItems: [],
			leagueItems: [],
			newsItems: [],
			newItemheadline: [],
			storiesnlse: [],
			postsnba: [],
			postsmlb: [],
			postsnfl: [],
			postscollege: [],
			postssoccer: [],
			postsboxing: [],
			postscollfootball: [],
			values: null,
		};
	},
	methods: {
		isBanner(val) {
			this.isBannerAvailable = val;
		},
		handleBannerStatus(hasData) {
			this.isBannerDataAvailable = hasData;
			this.isLoadingBanner = false; // Stop the spinner once the data status is known
		},
		handleBannerLoaded() {
			this.isLoadingBanner = false; // Set to false when Banner is loaded
		},
		displayLeagueOnLoad(val) {
			this.isLoadingLeague = val; // Set to false when Banner is loaded
		},
		displayNewsOnLoad(val) {
			this.isLoadingNews = val;
		},
		displayScheduleOnLoad(val) {
			this.isLoadingSchedule = val;
		},
		handleNewsDataAvailability(hasData) {
			this.hasNewsData = hasData;
		},
		dateformat(date) {
			var dt = new Date(date);
			return (
				(dt.getMonth() + 1).toString() +
				"/" +
				(dt.getDate() + 1).toString() +
				"/" +
				dt.getFullYear().toString()
			);
		},
		convert(data) {
			for (var i = 0; i < data.length; i++) {
				if (data[i].title.length > 90) {
					data[i].title = data[i].title.substr(0, 90) + "...";
				}
				var dt = new Date(data[i].isoDate);
				data[i].pubDate =
					(dt.getMonth() + 1).toString() +
					"/" +
					(dt.getDate() + 1) +
					"/" +
					dt.getFullYear().toString() +
					" " +
					dt.getHours().toString() +
					":" +
					dt.getMinutes().toString() +
					":" +
					dt.getSeconds().toString() +
					" GMT";
			}
			return data;
		},
		async getData() {
			try {
				const response = await this.$http.get(
					"https://dev-rss.nlse.com/nba/rss/"
				);
				const parser = new RSSParser();
				parser.parseString(response.data, (err, parsed) => {
					this.loading = false;
					if (err) {
						console.log(err);
					} else {
						this.postsnba = parsed.items.slice(0, 10);
						this.postsnba = this.convert(this.postsnba);
					}
				});
			} catch (error) {
				console.log(error);
			}
			try {
				const response = await this.$http.get(
					"https://dev-rss.nlse.com/mlb/rss/"
				);
				const parser = new RSSParser();
				parser.parseString(response.data, (err, parsed) => {
					this.loading = false;
					if (err) {
						console.log(err);
					} else {
						this.postsmlb = parsed.items.slice(0, 10);
						this.postsmlb = this.convert(this.postsmlb);
						console.log(this.posts);
					}
				});
			} catch (error) {
				console.log(error);
			}
			try {
				const response = await this.$http.get(
					"https://dev-rss.nlse.com/nfl/rss/"
				);
				const parser = new RSSParser();
				parser.parseString(response.data, (err, parsed) => {
					this.loading = false;
					if (err) {
						console.log(err);
					} else {
						this.postsnfl = parsed.items.slice(0, 10);
						this.postsnfl = this.convert(this.postsnfl);
						console.log(this.posts);
					}
				});
			} catch (error) {
				console.log(error);
			}
			try {
				const response = await this.$http.get(
					"https://dev-rss.nlse.com/college-basketball/rss/"
				);
				const parser = new RSSParser();
				parser.parseString(response.data, (err, parsed) => {
					this.loading = false;
					if (err) {
						console.log(err);
					} else {
						this.postscollege = parsed.items.slice(0, 10);
						this.postscollege = this.convert(this.postscollege);
						console.log(this.postscollege);
					}
				});
			} catch (error) {
				console.log(error);
			}
			try {
				const response = await this.$http.get(
					"https://dev-rss.nlse.com/college-football/rss/"
				);
				const parser = new RSSParser();
				parser.parseString(response.data, (err, parsed) => {
					this.loading = false;
					if (err) {
						console.log(err);
					} else {
						this.postscollfootball = parsed.items.slice(0, 10);
						this.postscollfootball = this.convert(this.postscollfootball);
					}
				});
			} catch (error) {
				console.log(error);
			}
		},
	},
	async created() {
		// this.getData();
		// const todayDate = today.toISOString().split("T")[0];
		// Get Data from the API
		// 	{
		// 		videosnles(orderBy: createdAt_DESC) {
		// 			video {
		// 				url
		// 			}
		// 			title
		// 			description
		// 		}
		// 		homeSliders(orderBy: order_ASC) {
		// 			title
		// 			text
		// 			accented
		// 			src {
		// 				url
		// 			}
		// 		}
		// 		newsSliders(orderBy: date_DESC) {
		// 			title
		// 			subtitle
		// 			desc
		// 			date
		// 			image {
		// 				url
		// 			}
		// 			release {
		// 				url
		// 			}
		// 		}
		// 		featuredSliders {
		// 			title
		// 			description
		// 			date
		// 			starring
		// 			genre
		// 			image {
		// 				url
		// 			}
		// 			background {
		// 				url
		// 			}
		// 		}
		// 		channelSliders {
		// 			link
		// 			image {
		// 				url
		// 			}
		// 			title
		// 		}
		// 		channels(orderBy: popular_DESC) {
		// 			popular
		// 			provider
		// 			channel
		// 		}
		// 		newsitems(orderBy: createdAt_DESC) {
		// 			title
		// 			shorttitle
		// 			date
		// 			summary
		// 			text {
		// 				raw
		// 				html
		// 				markdown
		// 				text
		// 			}
		// 			category
		// 			image {
		// 				url
		// 			}
		// 		}
		// 	}
		// `
		// );
		// this.homeSliderData = data.homeSliders;
		// this.featuredItems = data.featuredSliders;
		// this.channelItems = data.channelSliders;
		// this.channelList = data.channels;
		// this.newsItems = data.newsitems.slice(0, 2);
		// this.newItemheadline = data.newsitems.slice(2);
		// this.storiesnlse = data.videosnles.slice(0, 3);
		// this.leagueItems = [
		// 	{
		// 		image: require("@/assets/images/frontend/league/SFL_tile.png"),
		// 		title: "AT&T U-Verse",
		// 		link: "https://www.att.com/u-verse-tv/",
		// 	},
		// 	{
		// 		image: require("@/assets/images/frontend/league/CEBL_2.png"),
		// 		title: "DirectTV",
		// 		link: "https://www.directv.com/",
		// 	},
		// 	{
		// 		image: require("../../assets/images/frontend/league/WPT-web.png"),
		// 		title: "Verizon Fios",
		// 		link: "https://www.verizon.com/Banner/fios/",
		// 	},
		// 	// { image: require('@/assets/images/frontend/league/fubo.jpg'), title: 'FuboTV', link: 'https://www.fubo.tv/welcome' },
		// 	// { image: require('@/assets/images/frontend/league/4com.png'), title: '4 Com', link: 'https://www.4com.co.uk/' }
		// ];
		// for (let i = 0; i < this.newsItems.length; i++) {
		// 	this.newsItems[i].date = this.dateformat(this.newsItems[i].date);
		// }
	},
};
</script>
<style scoped>
.banner-margin {
	margin-top: -15vh;
}

.loader {
	border: 5px solid #f3f3f3; /* Light grey border */
	border-top: 5px solid #000000; /* Blue border */
	border-radius: 50%;
	width: 50px;
	height: 50px;
	animation: spin 2s linear infinite;
}
</style>
